import React from 'react'
import { Link } from 'react-router-dom'

export default function VirtualHybridEvent() {
  return (
    <>
     <div className="event-managment-section p-0 m-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <img src="assets/images/service/3.jpg" width="100%" alt="img"/>
          </div>
        </div>
      </div>
     </div>

     <section className="rts-feature-six section__padding ">
        <div className="container">
            <div className="row">
                <div className="rts-section text-center">
                    <h3 className="rts-section__title sal-animate" data-sal="slide-down" data-sal-delay="100" data-sal-duration="800">Connecting Thousands Worldwide with<br/> Virtual Event Solutions</h3>
                    <p className="rts-section__description sal-animate" data-sal="slide-down" data-sal-delay="300" data-sal-duration="800">Virtual Events are compelling as it greatly allows you to increase the number of people who can attend. Up to 20,000 remote attendees can enjoy <br/>an immersive event experience worldwide without any hassles</p>
                </div>
            </div>
        </div>
    </section>

    <div className="rts-about wp__hosting__option position-relative section__padding pt-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down mb-3"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{maxWidth:'100%'}}
                >
                  Streamline and Market Your Engaging Events Across Multiple Platforms
                </h3>
               <ul>
                <li>Develop a dedicated microsite for your event and leverage its promotion across various digital and non-digital channels.</li>
                <li>Utilise social media platforms and email invitations to attract a larger number of attendees.</li>
                <li>Enhance viewer engagement by offering shopping vouchers and certificates of attendance as rewards.</li>
                <li>Strengthen relationships by sending personalised post-event follow-up messages.</li>
               </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="rts-about__image align-items-start">
                <img
                  src="assets/images/service/4530890-scaled.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="rts-about__image align-items-start">
                <img
                  src="assets/images/service/moojic-graphic.png"
                  alt=""
                  width="90%"
                />
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down mb-3"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{maxWidth:'100%'}}
                >
                Transform Your Event Experience 
                </h3>
               <ul>
                <li>	Virtual events provide an opportunity to seamlessly integrate your physical and digital experiences through event management automation.</li>
                <li>	Accommodate large numbers of participants on a single platform and enhance networking capabilities.</li>
                <li>	Utilise webcasting technology to communicate and connect with end-users in real-time, regardless of their location.</li>
                <li>	Customise your virtual event platform to collaborate with potential audiences from around the globe.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="rts-about-shape"></div>
      </div>

      <section className="rts-feature-six section__padding py-5 ">
        <div className="container">
            <div className="row">
                <div className="rts-section text-center">
                    <h3 className="rts-section__title sal-animate" data-sal="slide-down" data-sal-delay="100" data-sal-duration="800">Crafting Immersive Virtual Experiences</h3>
                    <p className="rts-section__description sal-animate" data-sal="slide-down" data-sal-delay="300" data-sal-duration="800">We at LiveYards, we don't just stream events, we curate unforgettable experiences.  <br/>We achieve this through a suite of powerful features designed to captivate your audience and encourage meaningful connections</p>
                </div>
            </div>
        </div>
    </section>

    <section className="rts-service section__padding pt-0">
        <div className="container">
            <div className="row">
                <div className="rts-service__wrapper">
                    <div className="rts-service__column">
                        <div className="rts-service__single">
                            <Link to="#" className="rts-service__single--title">Virtual Stage</Link>
                            <p className="rts-service__single--excerpt">
                            Virtual stage serves as the focal point for your online attendees, seamlessly integrating all aspects of their virtual event experience. This dynamic platform allows participants to access video content, engage in networking opportunities, explore sponsor booths, and immerse themselves in interactive virtual stage rooms.
                            </p>
                        </div>
                    </div>
                    <div className="rts-service__column">
                        <div className="rts-service__single">
                            <Link to="wordpress-hosting.html" className="rts-service__single--title">Virtual Set</Link>
                            <p className="rts-service__single--excerpt">
                            Our advanced virtual set technology transforms your event's backdrop in real-time, creating a captivating television studio experience. With cutting-edge UI/UX effects, we offer fully customizable 360-degree backgrounds, including stunning 3D virtual stages, seamless chrome video stage integration, and reliable green screen assistance.
                            </p>
                        </div>
                    </div>

                    <div className="rts-service__column">
                        <div className="rts-service__single">
                            <Link to="vps-hosting.html" className="rts-service__single--title">Microsite Virtual Event</Link>
                            <p className="rts-service__single--excerpt">
                            Our tailored-branded virtual microsite serves as a dedicated event website, providing attendees with all the essential information they need for your upcoming virtual event. This comprehensive platform acts as an ideal hub for your content marketing strategies, showcasing promotion posts, blogs, articles, agenda details, guest information, and a live streaming screen that envelops the event.
                            </p>
                        </div>
                    </div>

                    <div className="rts-service__column">
                        <div className="rts-service__single">
                            <Link to="#" className="rts-service__single--title">Hybrid Event Management</Link>
                            <p className="rts-service__single--excerpt">
                            Hybrid and virtual events taking centre stage, our innovative technology enables us to create a fully customised virtual event arena. From engaging Q&A sessions and interactive polls to grand auditoriums and virtual exhibition sections, we leverage the latest technology to deliver an immersive experience that captivates your audience.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <div className="rts-data-center no-bg fix section__padding pb-0 pt-0" >
        <div className="container">
          <div className="rts-cta-two__wrapper justify-content-center">
            <div className="row">
              <div className="col-12">
                <div className="rts-section text-center mb-0" id='virtual-events'>
                  <h3
                    className="rts-section__title sal-animate text-white"
                    data-sal="slide-down"
                    data-sal-delay="300"
                    data-sal-duration="800"
                  >
                    LiveYards is your ultimate destination for organising <br/> any type of Virtual Event!
                  </h3>
                <ul className="expertise-items">
                  <li>Rewards & Recognition (R&R)</li>
                  <li>Training Program</li>
                  <li>Townhall</li>
                </ul>
                <ul className="expertise-items">
                  <li>Product Launch</li>
                  <li>Press Conference</li>
                  <li>Annual General Meetings (AGM) </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rts-about-reseller section__padding area-2 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="hosting-info w-550 mt-md-0">
                        <h3 className="hosting-info__title sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">Amplify Your Virtual & Hybrid Event Experience with LiveYards</h3>
                        <p className="description sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">Experience the difference of working with our team of dedicated professionals who are ready to support you every step of the way in organising your virtual event. From ideation and strategy to flawless execution, we're here to make your event a success.
                        <br/><br/>
                        Schedule a Conversation with Our Experts
                        </p>
                        <img src="assets/images/pricing/pricing-image.svg" alt="" />
                       
                    </div>
                </div>
                <div className="col-lg-6 padding-bottom-75">
                    <div className="hosting-about-image-bg"></div>
                    <div className="hosting-about-image">
                        <div className="img-one">
                            <img src="assets/images/about/resell/image-3.png" width="378" height="400" alt="" />
                        </div>
                        <div className="img-two">
                            <img src="assets/images/about/resell/image-4.png" width="247" height="347" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
