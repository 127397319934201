import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import sal from "sal.js";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Careers from "./pages/Careers";
import MarketResearch from "./pages/MarketResearch";
import EventManagement from "./pages/EventManagement";
import OurServices from "./pages/OurServices";
import LiveStreamingWebCast from "./pages/LiveStreamingWebCast";
import VirtualHybridEvent from "./pages/VirtualHybridEvent";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Header />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<AboutUs />} path="/aboutus" />
          <Route element={<ContactUs />} path="/contactus" />
          <Route element={<Blog />} path="/blog" />
          <Route element={<BlogDetails />} path="/blogdetails" />
          <Route element={<Careers />} path="/careers" />
          <Route element={<MarketResearch />} path="/market-research" />
          <Route element={<EventManagement />} path="/event-management" />
          <Route element={<OurServices />} path="/our-services" />
          <Route
            element={<LiveStreamingWebCast />}
            path="/live-streaming-webCast"
          />
          <Route
            element={<VirtualHybridEvent />}
            path="/virtual-hybrid-event"
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
