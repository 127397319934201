import React, { useEffect, useState } from "react";
import sal from "sal.js";
import "../../node_modules/sal.js/dist/sal.css";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function Home() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    sal();
  });

  return (
    <>
      <div className="video-section" id="desktop-video">
        <ReactPlayer
        className="main-video"
          width="100%"
          height="723px"
          playing={true}
          loop={true}
          muted
          controls={false}
          url={[
            { src: "../video/webcasting promo video.mp4", type: "video/mp4" },
          ]}
        />
      </div>
      <div className="video-section2 d-none" id="mobile-video">
        <ReactPlayer
        className="main-video2"
          width="100%"
          height="723px"
          playing={true}
          loop={true}
          muted
          controls={false}
          url={[
            { src: "../video/mobile-video.mp4", type: "video/mp4" },
          ]}
        />
      </div>

      {/* about part */}
      <div className="rts-about-reseller section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 padding-bottom-75">
              <div className="hosting-about-image-bg"></div>
              <div className="hosting-about-image">
                <div className="img-one">
                  <img
                    src="assets/images/about/resell/image-1.png"
                    width="378"
                    height="400"
                    alt=""
                  />
                </div>
                <div className="img-two">
                  <img
                    src="assets/images/about/resell/image-2.png"
                    width="247"
                    height="347"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hosting-info">
                <h3
                  className="hosting-info__title"
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  About Live Yards
                </h3>
                <p
                  className="description"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  LiveYards wasn't born when live streaming was mainstream in
                  India, we were there when it was just a murmur. Established in
                  YYYY, we were among the first to set the bar for exceptional
                  communication experiences in this exciting new world. Fast
                  forward a decade, and our unwavering dedication to customer
                  focus, rigorous execution, and a wealth of experience allows
                  us to tackle the most intricate live streaming challenges with
                  effortless ease.
                </p>
                <div className="single-about-info">
                  <div
                    className="single"
                    data-sal="slide-down"
                    data-sal-delay="200"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <img
                        src="assets/images/about/resell/support.svg"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <p className="mb-0">Established in 2013</p>
                    </div>
                  </div>
                  <div
                    className="single"
                    data-sal="slide-down"
                    data-sal-delay="100"
                    data-sal-duration="800"
                  >
                    <div className="icon">
                      <img
                        src="assets/images/about/resell/speed-meter.svg"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <p className="mb-0">
                        Change the icon of 1000+ events and keep it relevant.
                      </p>
                    </div>
                  </div>
                </div>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={handleShow}
                  className="btn__two btn__long secondary__bg secondary__color"
                >
                  Know Us Better <i className="fa-regular fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* service part */}
      <div className="rts-affiliate-earn section__padding py-0">
        <div className="rts-section text-center">
          <h3 className="rts-section__title">Our Services</h3>
        </div>
        <div className="card mx-5">
          <div className="container px-5">
            <Tabs
              defaultActiveKey="Virtual & Hybrid Event"
              id="justify-tab-example"
              justify
            >
              <Tab
                eventKey="Virtual & Hybrid Event"
                title="Virtual & Hybrid Event"
              >
                <div className="row p-3">
                  <div className="col-lg-6">
                    <img
                      src="assets/images/service/sol-main.webp"
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 p-5 tabconrent">
                    <h4 style={{ marginTop: "22px" }}>
                      Virtual & Hybrid Event
                    </h4>
                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      Virtual Events are compelling as it greatly allows you to
                      increase the number of people who can attend. Up to 20,000
                      remote attendees can enjoy an immersive event experience
                      worldwide without any hassles
                    </p>

                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      Virtual Events are compelling as it greatly allows you to
                      increase the number of people who can attend. Up to 20,000
                      remote attendees can enjoy an immersive event experience
                      worldwide without any hassles
                    </p>

                    <Link to="/virtual-hybrid-event" className="common-btn">
                      Learn More
                    </Link>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Live Streaming & Webcast"
                title="Live Streaming & Webcast"
              >
                <div className="row p-3">
                  <div className="col-lg-6">
                    <img
                      src="assets/images/service/sol-main1.webp"
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 p-5 tabconrent">
                    <h4>Live Streaming & Webcast</h4>
                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      In today's digital age, live streaming has emerged as a
                      powerful tool for communication. Widely utilised in
                      virtual and hybrid events, this technology offers
                      unmatched reach to a diverse audience, enabling real-time
                      dissemination of messages across various platforms. With
                      just an internet connection, you can connect with
                      audiences worldwide.
                    </p>

                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      At BroadcastWorks, we empower you to share your narrative.
                      Leveraging cutting-edge technology and tailored features
                      such as security protocols, interactive chatbots,
                      customised branding, and live polling, we ensure that your
                      voice resonates with a global audience
                    </p>

                    <Link to="/live-streaming-webCast" className="common-btn">
                      Learn More
                    </Link>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Event Management" title="Event Management">
                <div className="row p-3">
                  <div className="col-lg-6">
                    <img
                      src="assets/images/service/sol-main2.webp"
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 p-5 tabconrent">
                    <h4>Event Management</h4>
                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      Are you planning a grand event that requires meticulous
                      planning, flawless execution, and exceptional attention to
                      detail? Look no further than our event management services
                      at LiveYards. We specialise in bringing your vision to
                      life, whether it's a corporate event, product launch, gala
                      dinner, or any other special occasion.
                    </p>

                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      Ready to start planning your next grand event? Let us make
                      it happen with our event management services at LiveYards.
                      Contact us today to schedule a consultation and discover
                      how we can bring your vision to life and create an
                      unforgettable event that will leave a lasting impression
                    </p>

                    <Link to="/event-management" className="common-btn">
                      Learn More
                    </Link>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Market Research" title="Market Research">
                <div className="row p-3">
                  <div className="col-lg-6">
                    <img
                      src="assets/images/service/sol-main3.webp"
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 p-5 tabconrent">
                    <h4>Market Research</h4>
                    <p
                      className="description sal-animate"
                      data-sal="slide-down"
                      data-sal-delay="100"
                      data-sal-duration="800"
                    >
                      Welcome to LiveYards, your ultimate destination for
                      cutting-edge market research technical support in the
                      fast-paced and dynamic world of FMCG (Fast Moving Consumer
                      Goods). As a forward-thinking and innovative firm, we
                      specialise in providing unparalleled technical expertise
                      to drive the success of your market research endeavours.
                    </p>

                    <Link to="/market-research" className="common-btn">
                      Learn More
                    </Link>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {/* info part */}
      <div className="rts-support pt--120">
        <div className="container">
          <div className="row">
            <div className="rts-support__wrapper px-5">
              <div className="rts-support__wrapper--content">
                <h3
                  className="title"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  24/7 World-Class Support
                </h3>
                <p
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  Run into trouble? Contact our Customer Success team any time
                  via live chat or email.
                </p>
                <div
                  className="feature"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                >
                  <ul className="feature__list">
                    <li className="feature__item">
                      Receive professional Brodcusting support
                    </li>
                    <li className="feature__item">
                      Our MERN specialists are available round
                    </li>
                  </ul>
                </div>
                <Link
                  to="#"
                  className="rts-btn rts-btn-primary support-btn"
                >
                  Get Support <i className="fa-regular fa-chevron-right"></i>
                </Link>
              </div>
              <div
                className="rts-support__wrapper--image"
                data-sal="slide-left"
                data-sal-delay="400"
                data-sal-duration="800"
              >
                <img src="assets/images/support/support__image.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* blog part */}
      <section className="rts-blog pt--120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="rts-section w-510 text-center">
              <h2
                className="rts-section__title"
                data-sal="slide-down"
                data-sal-delay="100"
                data-sal-duration="800"
              >
                Blog
              </h2>
              <p
                className="rts-section__description"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="800"
              >
                Choose from data centers worldwide to store content your website
                visitors Cloudflare, has a network
              </p>
            </div>
          </div>

          <div className="row g-30">
            <div className="col-lg-4 col-sm-6">
              <div
                className="rts-blog__single"
                data-sal="slide-down"
                data-sal-delay="600"
                data-sal-duration="800"
              >
                <Link to="/blogdetails">
                  <img
                    className="blog__thumb"
                    src="assets/images/blog/blog-post-details.jpg"
                    alt="blog post thumb"
                  />
                </Link>
                <div className="rts-blog__single--meta">
                  <div className="cat__date">
                    <Link to="#" className="cat">
                     Blog
                    </Link>
                    <span className="date">08 April, 2024</span>
                  </div>
                  <Link to="/blogdetails" className="title">
                  Revolutionise Your Pharma Product Launch Strategy
                  </Link>
                  <div className="rts-blog__single--author">
                    <div className="author">
                      <img src="assets/images/author/author__one.png" alt="" />
                    </div>
                    <div className="author__content">
                      <Link to="#">Mack jon</Link>
                      <span>Artical Editor </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className="rts-blog__single"
                data-sal="slide-down"
                data-sal-delay="600"
                data-sal-duration="800"
              >
                <Link to="/blogdetails">
                  <img
                    className="blog__thumb"
                    src="assets/images/blog/blog-post-details.jpg"
                    alt="blog post thumb"
                  />
                </Link>
                <div className="rts-blog__single--meta">
                  <div className="cat__date">
                    <Link to="#" className="cat">
                     Blog
                    </Link>
                    <span className="date">08 April, 2024</span>
                  </div>
                  <Link to="/blogdetails" className="title">
                  Revolutionise Your Pharma Product Launch Strategy
                  </Link>
                  <div className="rts-blog__single--author">
                    <div className="author">
                      <img src="assets/images/author/author__one.png" alt="" />
                    </div>
                    <div className="author__content">
                      <Link to="#">Mack jon</Link>
                      <span>Artical Editor </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className="rts-blog__single"
                data-sal="slide-down"
                data-sal-delay="600"
                data-sal-duration="800"
              >
                <Link to="/blogdetails">
                  <img
                    className="blog__thumb"
                    src="assets/images/blog/blog-post-details.jpg"
                    alt="blog post thumb"
                  />
                </Link>
                <div className="rts-blog__single--meta">
                  <div className="cat__date">
                    <Link to="#" className="cat">
                     Blog
                    </Link>
                    <span className="date">08 April, 2024</span>
                  </div>
                  <Link to="/blogdetails" className="title">
                  Revolutionise Your Pharma Product Launch Strategy
                  </Link>
                  <div className="rts-blog__single--author">
                    <div className="author">
                      <img src="assets/images/author/author__one.png" alt="" />
                    </div>
                    <div className="author__content">
                      <Link to="#">Mack jon</Link>
                      <span>Artical Editor </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured clients */}
      <section className="rts-blog pt-50">
        <div className="container">
          <div className="row pt-120 sm-pt-50">
            <div className="col-lg-12">
              <div className="rts-brand__wrapper">
                <div className="rts-brand__wrapper--text">
                  <h3 className="animated fadeIn">Featured Client</h3>
                  <div className="rts-brand__wrapper--text-review">
                    <div className="review">
                      <div className="star">
                        Excellent{" "}
                        <img src="assets/images/brand/review-star.svg" alt="" />
                      </div>
                    </div>
                    <div className="review__company">
                      954 reviews on{" "}
                      <img src="assets/images/brand/trust-pilot.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rts-brand__slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/1.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/2.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/3.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/4.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/5.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/6.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          {" "}
                          <img src="assets/images/brand/7.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/8.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/9.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/10.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/11.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/12.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/13.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/14.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/15.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/16.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/17.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/18.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/19.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/20.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/21.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <Link to="#">
                          <img src="assets/images/brand/22.png" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal  */}
      <Modal className="enq-modal" show={show} onHide={handleClose} centered>
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <h3>Schedule a Demo</h3>
          <p>
            Book a Live demo and get all your queries resolved with our expert
          </p>
          <Form className="enq-form">
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Name *" />
              <Form.Control type="email" placeholder="Email ID *" />
              <Form.Control type="number" placeholder="Phone *" />
              <Form.Control type="text" placeholder="City *" />
              <Form.Control type="text" placeholder="Company *" />
              <Form.Control
                type="text"
                placeholder="Type of Event you are Planning *"
              />
              <Form.Control
                as="textarea"
                placeholder="Message"
                style={{ height: "50px" }}
              />
              <Form.Check 
                type="checkbox"
                label="I agree to receive product communications from Dreamcast"
              />
            </Form.Group>
            <Button className="submit-btn" variant="primary" type="submit">
              Request a Demo
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
