import React, { useEffect } from "react";
import sal from "sal.js";
import "../../node_modules/sal.js/dist/sal.css";
import { Link } from "react-router-dom";

export default function BlogDetails() {
  useEffect(() => {
    sal();
  });
  return (
    <>
      <div className="rts-blog-details section__padding mt-5 pb-5">
        <div className="container">
          <div className="row g-40">
            <div className="col-lg-8">
              <article className="blog-details">
                <div className="blog-details__featured-image">
                  <img
                    src="assets/images/blog/blog-post-details.jpg"
                    alt="blog post"
                  />
                </div>
                <div className="blog-details__article-meta mt--40">
                  <Link to="#">
                    <span>
                      <i className="fa-light fa-user"></i>
                    </span>
                    Jone Smit
                  </Link>
                  <span>
                    <span>
                      <i className="fa-light fa-clock"></i>
                    </span>
                    20 Jan 2024, 10:30 pm
                  </span>
                  <Link to="#">
                    <span>
                      <i className="fa-sharp fa-light fa-tags"></i>
                    </span>
                    Blog
                  </Link>
                </div>
                <h3 className="blog-title">
                  Fresh Perspectives, Practical Tips, and Industry Best
                  Practices
                </h3>
                <p>
                  Are you in the pharmaceutical industry gearing up for a
                  product launch? Do you want to ensure that your launch
                  strategy stands out from the competition, captivates your
                  target audience, and drives success in the market? Look no
                  further – LiveYards is here to revolutionise your pharma
                  product launch strategy{" "}
                </p>
                <p>
                  In today's fiercely competitive pharmaceutical landscape, a
                  well-planned and executed product launch is more crucial than
                  ever. It sets the foundation for your product's success,
                  establishes its position in the market, and creates a lasting
                  impact on healthcare professionals and patients alike. At
                  LiveYards, we understand the challenges you face and are here
                  to equip you with fresh perspectives, practical tips, and
                  industry best practices to achieve a truly remarkable product
                  launch
                </p>
                <blockquote className="rts-blockquote">
                  <h4>
                    Fresh Perspectives: Thinking Beyond Traditional Approaches
                  </h4>
                  <p>
                    To revolutionise your product launch strategy, one must be
                    willing to think beyond traditional approaches. At
                    LiveYards, we challenge conventional thinking and encourage
                    you to embrace innovative ideas. Our team of experts will
                    provide fresh perspectives to help you differentiate your
                    product in a crowded market By conducting comprehensive
                    market research, we decipher industry trends, patient needs,
                    and untapped opportunities. These insights will enable us to
                    craft a launch strategy that resonates with your target
                    audience and taps into the market gaps, setting your product
                    apart from the competitio
                  </p>
                </blockquote>
                <h4>
                  Practical Tips: Navigating the Complexities of a Product
                  Launch
                </h4>
                <p>
                  Launching a pharmaceutical product is a complex undertaking,
                  with numerous regulatory, marketing, and communication aspects
                  to consider. Our team of experienced professionals at
                  LiveYards will guide you through each step of the process,
                  offering practical tips to maximise the impact of your launch.
                </p>
                <p>
                  We assist you in developing a robust marketing plan that
                  encompasses both traditional and digital channels to reach
                  your intended audience effectively. From identifying key
                  opinion leaders to crafting persuasive messaging and
                  educational materials, we ensure your launch strategy is
                  comprehensive and tailored to generate enthusiasm and
                  adoption. Additionally, we can help you leverage the power of
                  digital platforms to engage with healthcare professionals,
                  patients, and other stakeholders. By harnessing the potential
                  of social media, online communities, and targeted advertising,
                  we enable you to connect with your audience in real-time and
                  foster lasting relationships
                </p>
                <h4>
                Industry Best Practices: Drawing from Successful Campaigns
                </h4>
                <p>At LiveYards, we have a proven track record of delivering successful product launches in the pharmaceutical industry. Our team stays abreast of the latest industry best practices, drawing inspiration from past successes to inform our strategies. With our finger on the pulse of the pharma landscape, we help you leverage the most effective techniques to make your launch a resounding success.</p>
                <p>We analyse data and monitor market dynamics to adapt our approach to your specific product and target market. From pre-launch activities to post-launch monitoring, we provide you with ongoing support and guidance to ensure your product achieves its full potential.</p>

                <h4>
                Partner with LiveYards to Revolutionise Your Pharma Product Launch Strategy
                </h4>
                <p>
                Revolutionise your pharma product launch strategy with LiveYards. Our team of highly skilled professionals is committed to your success and will work closely with you to develop and execute a launch strategy that drives results. Contact us today and unlock fresh perspectives, practical tips, and industry best practices that will transform your launch from ordinary to extraordinary. Together, let's make your product launch a resounding success that leaves a lasting impact on the pharmaceutical industry.
                </p>
              
              
              </article>
              <div className="blog-info">
                <div className="blog-share">
                  <div className="share">Share:</div>
                  <div className="social__media--list">
                    <Link to="#" className="media">
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                    <Link to="#" className="media">
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to="#" className="media">
                      <i className="fa-brands fa-linkedin"></i>
                    </Link>
                    <Link to="#" className="media">
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rts-sidebar">
                <div className="rts-single-widget recentpost-widget">
                  <h4 className="widget-title">Recent Post</h4>
                  <div className="recent-posts">
                    <div className="single-post">
                      <div className="thumb">
                        <img
                          src="assets/images/blog/blog-post-details.jpg"
                          alt=""
                          height="85"
                          width="85"
                        />
                      </div>
                      <div className="meta">
                        <span className="published">
                          <i className="fa-regular fa-clock"></i> 08 April, 2024
                        </span>
                        <h6 className="title">
                          <Link to="#">
                          Fresh Perspectives, Practical Tips, and Industry Best Practices
                          </Link>
                        </h6>
                      </div>
                    </div>
                   
                  </div>
                </div>

                <div className="rts-single-widget gallery-widget">
                  <h4 className="widget-title">Gallery Post</h4>
                  <div className="gallery-posts">
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-1.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-2.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-3.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-4.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-5.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                    <Link to="/blogdetails" className="thumb">
                      <img
                        src="assets/images/blog/gallery-post-6.png"
                        height="95"
                        width="95"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
