import React, { useEffect } from "react";
import sal from "sal.js";
import "../../node_modules/sal.js/dist/sal.css";
import { Link } from "react-router-dom";
export default function Blog() {
    useEffect(() => {
        sal();
      });
  return (
    <>
     <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
            <div className="row">
                <div className="banner-area">
                    <div className="rts-hosting-banner rts-hosting-banner__content blog__banner">
                        <span className="starting__price">Blog & Article </span>
                        <h1 className="banner-title">
                            Latest News & Articale
                        </h1>
                        <p className="slogan">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                        
                    </div>
                    <div className="rts-hosting-banner__image blog">
                        <img src="assets/images/banner/banner__blog__image.svg" alt="" />
                        <img className="shape one left-right" src="assets/images/banner/banner__blog__image-sm1.svg" alt="" />
                        <img className="shape two show-hide" src="assets/images/banner/banner__blog__image-sm2.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section className="rts-blog pt-120" id="rs-blog">
        <div className="container">
            <div className="row justify-content-sm-center justify-content-md-start g-30">
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-1.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__one.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Mack jon</Link>
                                    <span>Kolkata </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-2.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__two.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Zent jon</Link>
                                    <span>puna </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-3.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__three.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Ahmad Yeamin</Link>
                                    <span>puna </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-6.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__four.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Samira </Link>
                                    <span>Bolpur</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-7.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__one.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Mack jon</Link>
                                    <span>Kolkata </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-8.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__two.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Ashique Mahmud</Link>
                                    <span>puna </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-9.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__three.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Jhone Doe</Link>
                                    <span>Kolkata </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-10.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__four.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Eliza Stella</Link>
                                    <span>Bolpur</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10">
                    <div className="rts-blog__single">
                        <Link to="/blogdetails">
                            <img className="blog__thumb" src="assets/images/blog/blog-11.png" alt="blog post thumb" />
                        </Link>
                        <div className="rts-blog__single--meta">
                            <div className="cat__date">
                                <Link to="#" className="cat">Blog</Link>
                                <span className="date">19 Sep, 2023</span>
                            </div>
                            <Link to="/blogdetails" className="title">Attentive was born in 2015 help
                                sales teams VPS hosting</Link>
                            <div className="rts-blog__single--author">
                                <div className="author">
                                    <img src="assets/images/author/author__one.png" alt="" />
                                </div>
                                <div className="author__content">
                                    <Link to="#">Mack jon</Link>
                                    <span>Kolkata </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-pagination-area">
                <ul>
                    <li><Link to="#"><i className="fa-regular fa-chevron-left"></i></Link></li>
                    <li><Link className="active" to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#">3</Link></li>
                    <li>...</li>
                    <li><Link to="#">8</Link></li>
                    <li><Link to="#">9</Link></li>
                    <li><Link to="#"><i className="fa-regular fa-chevron-right"></i></Link></li>
                </ul>
            </div>
        </div>
    </section>
    </>
  )
}
