import React from "react";

export default function EventManagement() {
  return (
    <>
     <div className="event-managment-section p-0 m-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <img src="assets/images/service/1.jpg" width="100%" alt=""/>
          </div>
        </div>
      </div>
     </div>

     <div className="rts-about wp__hosting__option position-relative section__padding pb-5">
        <div className="container">
          <div className="row">
          <div className="col-xl-5 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Planning a Grand Event? We Make it Happen with Our Event Management Crew
                </h3>
                <p
                  className="description sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                >
                  Are you planning a grand event that requires meticulous planning, flawless execution, and exceptional attention to detail? Look no further than our event management services at LiveYards. We specialise in bringing your vision to life, whether it's a corporate event, product launch, gala dinner, or any other special occasion.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="rts-about__image">
                <img src="assets/images/service/event.png" alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-6">
              <div className="rts-about__content" >
                <h3
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{maxWidth:'100%'}}
                >
                  Our Event Managment Services
                </h3>
                <p
                  className="description sal-animate event-services"
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                  bold
                >
                 <span className="">Conceptualization and Planning :</span> <br/>
                 Our event management team will work with you to understand your vision and objectives. We will conceptualise and plan every aspect of the event, from the theme and design to the venue and entertainment, to make sure every detail is seamlessly executed.
                  <br />
                  <br />
                  <span className="">Budget Management :</span> <br/>
                  We understand the importance of sticking to a budget, and our team will work with you to develop a comprehensive budget plan that addresses all aspects of your event. Our aim is to deliver an unforgettable event without breaking the bank.
                  <br />
                  <br />
                  <span className="">Vendor Management :</span> <br/>
                  We have an extensive network of vendors and suppliers that we work with to provide the best possible services for your event. We handle all vendor negotiations and contracts on your behalf, ensuring a streamlined event planning process.
                  <br />
                  <br />
                  <span className="">On-site Management :</span> <br/>
                  On the day of the event, our team will be on-site to oversee every aspect of the event and ensure that everything runs smoothly. We take care of everything from setting up the venue to coordinating with vendors and managing emergencies.
                  <br />
                  <br />
                  <span className="">Post-Event Analysis and Reporting :</span> <br/>
                  Following the event, we will conduct a post-event analysis and provide a comprehensive report detailing the outcome of the event, including attendance, feedback, and other key metrics.
                </p>
              </div>
            </div>
           
          </div>
        </div>
        <div className="rts-about-shape"></div>
      </div>

      <div className="rts-about-reseller section__padding py-0">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 padding-bottom-75">
                    <div className="hosting-about-image-bg"></div>
                    <div className="hosting-about-image">
                        <div className="img-one">
                            <img src="assets/images/about/resell/image-1.png" width="378" height="400" alt="" />
                        </div>
                        <div className="img-two">
                            <img src="assets/images/about/resell/image-2.png" width="247" height="347" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="hosting-info">
                        <h3 className="hosting-info__title sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">Why Choose LiveYards for Your Event Management Needs</h3>
                        <div className="single-about-info">
                            <div className="single sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">
                               
                                <div className="content">
                                    <h6>Experience</h6>
                                    <p className="mb-0">
                                    We have years of experience planning and executing successful events for our clients, and we bring that knowledge and expertise to every new project.
                                    </p>
                                </div>
                            </div>
                            <div className="single sal-animate" data-sal="slide-down" data-sal-delay="100" data-sal-duration="800">
                               
                                <div className="content">
                                    <h6>Attention to Detail</h6>
                                    <p className="mb-0">
                                    We pride ourselves on our exceptional attention to detail and our ability to execute flawless events that exceed our clients' expectations
                                    </p>
                                </div>
                            </div>

                            <div className="single sal-animate" data-sal="slide-down" data-sal-delay="100" data-sal-duration="800">
                               
                               <div className="content">
                                   <h6>Collaboration</h6>
                                   <p className="mb-0">
                                   We work closely with our clients at every step of the process, from conceptualization to execution, to ensure that every aspect of the event meets their specific needs and objectives.
                                   </p>
                               </div>
                           </div>

                           <div className="single sal-animate" data-sal="slide-down" data-sal-delay="100" data-sal-duration="800">
                               
                               <div className="content">
                                   <h6>Flexibility</h6>
                                   <p className="mb-0">
                                   We understand that each client and event is unique, and we are flexible in our approach, ensuring that we cater to each client's specific requirements.
                                   </p>
                               </div>
                           </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




      <div className="rts-data-center no-bg fix section__padding pb-0 pt-5">
        <div className="container">
          <div className="rts-cta-two__wrapper justify-content-center">
            <div className="row">
              <div className="col-12">
                <div className="rts-section text-center mb-0">
                  <h3
                    className="rts-section__title sal-animate text-white"
                    data-sal="slide-down"
                    data-sal-delay="300"
                    data-sal-duration="800"
                  >
                    Contact Us Today to Plan Your Next Grand Event
                  </h3>
                  <p
                    className="rts-section__description w-790 sal-animate text-white"
                    data-sal="slide-down"
                    data-sal-delay="400"
                    data-sal-duration="800"
                  >
                    Ready to start planning your next grand event? Let us make
                    it happen with our event management services at LiveYards.
                    Contact us today to schedule a consultation and discover how
                    we can bring your vision to life and create an unforgettable
                    event that will leave a lasting impression
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
