import React from "react";

export default function OurServices() {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content blog__banner">
                <span className="starting__price">Our Services </span>
                <h1 className="banner-title">Why Wait? Get in Touch Today!</h1>
                <p className="slogan">
                  Ready to take your FMCG market research to the next level?
                  Partner with LiveYards and unlock a world of possibilities for
                  your brand. Contact us today to learn more about our
                  comprehensive range of services and discover how our technical
                  excellence and unwavering dedication can support your market
                  research success. Together, let's pave the way for a brighter
                  future in FMCG market research.
                </p>
              </div>
              <div className="rts-hero-two__images mobile-margin-top-100">
                <img
                  src="assets/images/banner/two/hero__two__illustration.svg"
                  alt=""
                />

                <img
                  className="shape-image two"
                  src="assets/images/banner/two/hero__two__illustration-sm2.svg"
                  alt=""
                />
                <img
                  className="shape-image three"
                  src="assets/images/banner/two/hero__two__illustration-sm3.svg"
                  alt=""
                />
                <img
                  className="shape-image four"
                  src="assets/images/banner/two/hero__two__illustration-sm4.svg"
                  alt=""
                />
                <img
                  className="shape-image five"
                  src="assets/images/banner/two/hero__two__illustration-sm5.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rts-why-choose bg-white section__padding">
        <div className="container">
          <div className="row">
            <div className="rts-section section-style-two justify-content-center">
              <div className="rts-section__two">
                <h2
                  className="title mb-0 sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  Our Populer Services
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-30">
            <div
              className="col-lg-4 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay="300"
              data-sal-duration="800"
            >
              <div className="card-feature">
                <div className="card-feature__single">
                  <div className="card-feature__single--icon">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.579 19.8333 37.579C29.634 37.579 37.579 29.634 37.579 19.8333C37.579 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3864 9.03291 31.4542 9.71168 31.0101 10.1329L23.4307 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9545 16.236L29.5338 8.65662C29.9415 8.24897 30.6024 8.24897 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3597 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.2611 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7696 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4918 8.35088 8.35088 13.4918 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                        fill="#2D3C58"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="card-feature__single--title">
                    Virtual Market Research Support
                  </h5>
                  <p className="card-feature__single--description">
                    Leveraging our virtual capabilities, LiveYards makes market
                    research easier than ever before. We provide the tools and
                    technical support you need to conduct online surveys,
                    virtual focus groups, and other remote research activities,
                    enabling you to gather valuable insights efficiently and
                    effectively
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay="400"
              data-sal-duration="800"
            >
              <div className="card-feature">
                <div className="card-feature__single">
                  <div className="card-feature__single--icon">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.579 19.8333 37.579C29.634 37.579 37.579 29.634 37.579 19.8333C37.579 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3864 9.03291 31.4542 9.71168 31.0101 10.1329L23.4307 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9545 16.236L29.5338 8.65662C29.9415 8.24897 30.6024 8.24897 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3597 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.2611 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7696 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4918 8.35088 8.35088 13.4918 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                        fill="#2D3C58"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="card-feature__single--title">
                    Data Management and Analysis
                  </h5>
                  <p className="card-feature__single--description">
                    At LiveYards, we understand that data is the lifeblood of
                    market research. Our team excels in managing and analysing
                    vast datasets, transforming raw information into actionable
                    insights. With our meticulous attention to detail and
                    expertise in data analysis, we empower you to make
                    data-driven decisions that propel your brand forward
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay="500"
              data-sal-duration="800"
            >
              <div className="card-feature">
                <div className="card-feature__single">
                  <div className="card-feature__single--icon">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.579 19.8333 37.579C29.634 37.579 37.579 29.634 37.579 19.8333C37.579 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3864 9.03291 31.4542 9.71168 31.0101 10.1329L23.4307 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9545 16.236L29.5338 8.65662C29.9415 8.24897 30.6024 8.24897 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3597 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.2611 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7696 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4918 8.35088 8.35088 13.4918 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                        fill="#2D3C58"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="card-feature__single--title">
                    Technical Infrastructure Setup
                  </h5>
                  <p className="card-feature__single--description">
                    Don't let technical hurdles hinder your market research
                    endeavours. LiveYards takes care of the setup and
                    maintenance of the technical infrastructure required for
                    seamless operations. We ensure optimal performance at every
                    stage of the research process, allowing you to focus on what
                    matters most - gathering meaningful market insights
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
