import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="rts-header style-one header__default">
        {/* <div className="rts-ht rts-ht__bg">
          <div className="container">
            <div className="row">
              <div className="rts-ht__wrapper">
                <div className="rts-ht__email">
                  <Link to="mailto:info@example.com">
                    <img
                      src="assets/images/icon/email.svg"
                      className="icon"
                      alt="email-icon"
                    />
                    contact@example.com
                  </Link>
                </div>

                <div className="rts-ht__links">
                  <div className="live-chat-has-dropdown">
                    <Link to="/blog" className="live__chat">
                      Blogs
                    </Link>
                  </div>
                  <div className="live-chat-has-dropdown">
                    <Link to="/contactus" className="live__chat">
                      Contact Us
                    </Link>
                  </div>
                  <div className="live-chat-has-dropdown">
                    <Link to="/careers" className="live__chat">
                      Careers
                    </Link>
                  </div>
                  <div className="login-btn-has-dropdown">
                    <Link to="/our-services" className="login__link">
                      Other Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="rts-header__wrapper" >
              <div className="rts-header__logo" style={{width:'16%'}}>
                <Link to="/" className="site-logo">
                  <div className="logo-white">
                    <img src="assets\images\logo\LIVE_YARD.png" width="60%" />
                  </div>

                  <div className="logo-dark">
                  <img src="assets\images\logo\LIVE_YARD.png" width="60%" />
                  </div>
                </Link>
              </div>
              <div className="d-flex align-items-center">
                <nav className="rts-header__menu" id="mobile-menu">
                  <div className="hostie-menu">
                    <ul className="list-unstyled hostie-desktop-menu">
                      <li className="menu-item">
                        <Link className="menu-items" to="/virtual-hybrid-event">
                          Virtual & Hybrid Event
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link
                          className="menu-items"
                          to="/live-streaming-webCast"
                        >
                          Live Streaming & Webcast
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link className="menu-items" to="/event-management">
                          Event Management
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link className="menu-items" to="/market-research">
                          Market Research
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div className="rts-header__right">
                  <button id="menu-btn" class="mobile__active menu-btn">
                    <i class="fa-sharp fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
