import React from "react";

export default function MarketResearch() {
  return (
    <>
      <div className="event-managment-section p-0 m-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <img
                src="assets/images/service/2.jpg"
                width="100%"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="rts-support-feature section__padding cloud__feature pt-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-xl-6 col-md-10">
              <div className="support-feature-image d-flex justify-content-center">
                <img
                  src="assets/images/feature/feature__image__three.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-12 col-12">
              <div className="support-feature__content">
                <h2
                  className="title mw-100 sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  Your Market Research Technical Support Partner
                </h2>
                <p
                  className="description sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  Welcome to LiveYards, your ultimate destination for
                  cutting-edge market research technical support in the
                  fast-paced and dynamic world of FMCG (Fast Moving Consumer
                  Goods). As a forward-thinking and innovative firm, we
                  specialise in providing unparalleled technical expertise to
                  drive the success of your market research endeavours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="rts-cloud-feature section__padding pt-0 pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="rts-section w-100">
              <h2
                className="title sal-animate"
                data-sal="slide-down"
                data-sal-delay="100"
                data-sal-duration="800"
              >
                Why Choose LiveYards For Your Market Research Needs?
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="card-box section__padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="card rounded p-3">
                <div className="card-body">
                  <h5>Technical Prowess</h5>
                  <p className="mb-0">
                    At LiveYards, we have assembled a team of experts with
                    extensive technical knowledge and skills. We excel in
                    seamlessly executing complex market research projects, even
                    in the virtual landscape. From data collection to analysis,
                    we are your trusted partner every step of the way
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded p-3">
                <div className="card-body">
                  <h5>FMCG Focus</h5>
                  <p className="mb-0">
                    We understand the intricate nuances of the FMCG industry.
                    Our deep understanding of its unique challenges and
                    opportunities allows us to tailor our support to meet your
                    brand's specific needs. With LiveYards by your side, you can
                    stay ahead of the curve and outperform your competitors
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded p-3">
                <div className="card-body">
                  <h5>Innovative Solutions</h5>
                  <p className="mb-0">
                    Innovation is at the heart of everything we do. We
                    constantly strive to harness the latest technologies and
                    techniques to deliver cutting-edge solutions for your market
                    research initiatives. Our commitment to innovation is a
                    driving force that fuels tangible results and helps you make
                    informed decisions for your brand
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card rounded p-3">
                <div className="card-body">
                  <h5>Reliable Partnership</h5>
                  <p className="mb-0">
                    Choosing LiveYards means gaining more than just a service
                    provider; it means gaining a reliable and dedicated partner
                    invested in your brand's success. We are with you every step
                    of the way, offering unmatched support and expertise.
                    Together, we will go above and beyond to help you achieve
                    your goals
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="rts-about wp__hosting__option position-relative section__padding pt-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down mb-3"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{ maxWidth: "100%" }}
                >
                  Data Management and Analysis
                </h3>
                <p>
                  At LiveYards, we understand that data is the lifeblood of
                  market research. Our team excels in managing and analysing
                  vast datasets, transforming raw information into actionable
                  insights. With our meticulous attention to detail and
                  expertise in data analysis, we empower you to make data-driven
                  decisions that propel your brand forward
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div
                className="rts-about__image align-items-start"
                style={{ minHeight: "317px" }}
              >
                <img
                  src="assets/images/service/4530890-scaled.png"
                  alt=""
                  width="70%"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div
                className="rts-about__image align-items-start"
                style={{ minHeight: "317px" }}
              >
                <img
                  src="assets/images/service/moojic-graphic.png"
                  alt=""
                  width="70%"
                />
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down mb-3"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{ maxWidth: "100%" }}
                >
                  Virtual Market Research Support
                </h3>
                <p>
                  Leveraging our virtual capabilities, LiveYards makes market
                  research easier than ever before. We provide the tools and
                  technical support you need to conduct online surveys, virtual
                  focus groups, and other remote research activities, enabling
                  you to gather valuable insights efficiently and effectively
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-7 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down mb-3"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                  style={{ maxWidth: "100%" }}
                >
                  Technical Infrastructure Setup
                </h3>
                <p>
                  Don't let technical hurdles hinder your market research
                  endeavours. LiveYards takes care of the setup and maintenance
                  of the technical infrastructure required for seamless
                  operations. We ensure optimal performance at every stage of
                  the research process, allowing you to focus on what matters
                  most - gathering meaningful market insights
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div
                className="rts-about__image align-items-start"
                style={{ minHeight: "317px" }}
              >
                <img
                  src="assets/images/service/technical.png"
                  alt=""
                  width="70%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rts-about-shape"></div>
      </div>

      <div className="rts-cta-two shared-page-bg">
        <div className="container">
          <div className="row">
            <div className="rts-cta-two__wrapper">
              <div className="cta-content" style={{ maxWidth: "100%" }}>
                <h4
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate mb-3"
                >
                  Why Wait? Get in Touch Today!
                </h4>
                <p className="text-white">
                  Ready to take your FMCG market research to the next level?
                  Partner with LiveYards and unlock a world of possibilities for
                  your brand. Contact us today to learn more about our
                  comprehensive range of services and discover how our technical
                  excellence and unwavering dedication can support your market
                  research success. Together, let's pave the way for a brighter
                  future in FMCG market research.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
