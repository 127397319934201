import React,{useState} from "react";
import { Link } from "react-router-dom";


export default function Footer() {
  
  return (
    <>
      <footer className="rts-footer site-footer-one section__padding">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-6 rts-footer__widget--column d-flex justify-content-center">
              <div className="rts-footer__widget footer__widget pb-5 text-center w-80" >
                <div className="d-inline-block mb-5">
                <div
                  className="logo-dark w-280"
                >
                 <img src="assets\images\logo\LIVE_YARD.png" width="80%" />
                </div>
                </div>
                <p className="brand-desc text-center">
                  LiveYards wasn't born when live streaming was mainstream in
                  India, we were there when it was just a murmur.
                </p>
              </div>
            </div>
          
            <div className="col-lg-12 col-md-12 col-sm-6 rts-footer__widget--column">
              <div className="rts-footer__widget footer__widget extra-padding">
                <div className="rts-footer__widget--menu ">
                  <ul className="d-flex">
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/virtual-hybrid-event">Virtual & Hybrid Event</Link>
                    </li>
                    <li>
                      <Link to="/live-streaming-webCast">
                        Live Streaming & Webcast
                      </Link>
                    </li>
                    <li>
                      <Link to="/event-management">Event Management</Link>
                    </li>
                    <li>
                      <Link to="/market-research">Market Research</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          

            <div className="col-lg-12 col-md-12 rts-footer__widget--column mt-5">
              <div className="rts-footer__widget footer__widget text-center">
                <h5 className="widget-title">social media</h5>
                <div className="social__media">
                  <div className="social__media--list">
                    <Link
                      to="https://www.facebook.com/liveyards.fb"
                      target="_blank"
                      className="media"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                    <Link
                      to="https://www.instagram.com/liveyards.in"
                      className="media"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/liveyards"
                      className="media"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </Link>
                    <Link
                      to="https://twitter.com/liveyards_in"
                      className="media"
                      target="_blank"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </Link>
                    <Link
                      to="http://www.pinterest.com/LiveYards"
                      className="media"
                      target="_blank"
                    >
                      <i className="fa-brands fa-pinterest"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="rts-footer__copyright text-center">
              <p>&copy; Copyright 2024. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>

      <div id="anywhere-home" className=""></div>

      {/* <!-- side bar area  --> */}
      <div id="side-bar" className="side-bar header-two">
        <button className="close-icon-menu">
          <i className="fa-sharp fa-thin fa-xmark"></i>
        </button>
        {/* <!-- mobile menu area start --> */}
        <div className="mobile-menu-main">
          <nav className="nav-main mainmenu-nav mt--30">
            <ul className="mainmenu metismenu" id="mobile-menu-active">
              <li className="">
                <Link to="/" className="main">
                  Home
                </Link>
              </li>
              <li className="">
                <Link to="/aboutus" className="main">
                  About Us
                </Link>
              </li>
              <li className="">
                <Link to="/virtual-hybrid-event" className="main">
                  Virtual & Hybrid Event
                </Link>
              </li>
              <li className="">
                <Link to="/live-streaming-webCast" className="main">
                  Live Streaming & Webcast
                </Link>
              </li>
              <li className="">
                <Link to="/event-management" className="main">
                  Event Management
                </Link>
              </li>
              <li className="">
                <Link to="/market-research" className="main">
                  Market Research
                </Link>
              </li>
              <li className="">
                <Link to="/our-services" className="main">
                  Other Services
                </Link>
              </li>
              <li className="">
                <Link to="/blog" className="main">
                  Blogs
                </Link>
              </li>
              <li className="">
                <Link to="/careers" className="main">
                  Careers
                </Link>
              </li>
              <li className="">
                <Link to="/contactus" className="main">
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>

          <ul className="social-area-one pl--20 mt--100">
            <li>
              <Link
                to="https://www.linkedin.com/company/liveyards"
                className="media"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin"></i>
              </Link>
            </li>
            <li>
              <Link
                to="https://twitter.com/liveyards_in"
                className="media"
                target="_blank"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.instagram.com/liveyards.in"
                className="media"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </Link>
            </li>
            <li>
              <Link
                to="http://www.pinterest.com/LiveYards"
                className="media"
                target="_blank"
              >
                <i className="fa-brands fa-pinterest"></i>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.facebook.com/liveyards.fb"
                target="_blank"
                className="media"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </Link>
            </li>
          </ul>
        </div>
        {/* <!-- mobile menu area end --> */}
      </div>
      {/* pre loader */}
      <div className="loader-wrapper">
        <div className="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>
      {/* uptotop process bar  */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "307.919",
            }}
          ></path>
        </svg>
      </div>





     
    </>
  );
}
