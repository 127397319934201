import React, { useEffect } from "react";
import sal from "sal.js";
import "../../node_modules/sal.js/dist/sal.css";
import { Link } from "react-router-dom";
export default function AboutUs() {
  useEffect(() => {
    sal();
  });
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content about__banner">
                <span
                  className="starting__price sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  About Live Yards
                </span>
                <h1
                  className="banner-title sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  Pioneering Live Streaming Solutions Since decade
                </h1>
                <p
                  className="slogan sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                >
                  What makes Site Ground unique is our extraordinary team of
                  individuals who put their passion.Fast forward a decade, and
                  our unwavering dedication to customer focus, rigorous
                  execution, and a wealth of experience allows us to tackle the
                  most intricate live streaming challenges with effortless ease.
                </p>
                <div
                  className="hosting-action sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  <Link
                    to="/contact-us"
                    className="btn__two secondary__bg secondary__color"
                  >
                    Contact Us <i className="fa-regular fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="rts-hosting-banner__image about">
                <img
                  src="assets/images/banner/banner-hero-about.png"
                  width="562"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="rts-hosting-feature-two section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="rts-hosting-feature-two__image sal-animate"
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="800"
              >
                <img
                  src="assets/images/hosting/hosting__feature__two.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="rts-hosting-feature-two__content">
                <div className="rts-section__two">
                  <h6
                    className="sub__title sal-animate"
                    data-sal="slide-down"
                    data-sal-delay="100"
                    data-sal-duration="800"
                  >
                    Let LiveYards bridge the gap and take your communication to
                    the next level
                  </h6>
                  <h2
                    className="title sal-animate"
                    data-sal="slide-down"
                    data-sal-delay="300"
                    data-sal-duration="800"
                  >
                    We don't just stream, we craft connections
                  </h2>
                  <p
                    className="description sal-animate"
                    data-sal="slide-down"
                    data-sal-delay="400"
                    data-sal-duration="800"
                  >
                    Here at LiveYards, innovation is in our DNA. We're a
                    passionate team that thrives on ideating and building
                    customised solutions around the world for our esteemed
                    clients. Our mission is clear: to empower businesses to
                    communicate, connect, and collaborate seamlessly, no matter
                    the distance
                  </p>
                </div>
                <div
                  className="feature sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="500"
                  data-sal-duration="800"
                >
                  <ul className="feature__list">
                    <li className="feature__item">
                      CTA: 1000+Events Handled | 100+mn Views | 2500+ Steamed
                      Clients | 50+ Teams | 10 Years of Experience.
                    </li>
                    <li className="feature__item">
                      Alt: 1000+ Events Streamed. Millions Captivated. A Decade
                      of Expertise. Let's Talk.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="rts-whois-result alice__blue section__padding">
        <div className="container">
          <div className="row">
            <div className="result-content">
              <div className="result-content__left">
                <h2 className="title fw-bold">About Journey</h2>
                <p>
                  LiveYards wasn't born out of replicating physical events, we
                  were born with the vision to create something entirely new:
                  unforgettable virtual experiences. Today, we're immensely
                  proud to celebrate a decade of pioneering this dynamic
                  industry
                </p>
                <p className="mb-0">
                  Over the past 10 years, we've pushed boundaries, fostered
                  connections, and helped countless brands unlock the power of
                  live streaming. We're excited to see what the future holds,
                  and we invite you to join us on the journey!
                </p>
              </div>
              <div className="result-content__right">
                <figure>
                  <img
                    src="assets/images/service/whois-image.png"
                    srcset="assets/images/service/whois-image2x.png 2x"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rts-support pt--60">
        <div className="container">
          <div className="row">
            <div className="rts-support__wrapper bg-white">
              <div className="rts-support__wrapper--content">
                <span
                  className="starting__price bg-primary p-2 px-4 text-white rounded sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  Vision to Reality
                </span>
                <h2
                  className="title fw-bold sal-animate mt-3"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  Celebrating 5 Years of LiveYards
                </h2>
                <p
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  This incredible journey wouldn't have been possible without
                  our #LiveYards – our amazing team and our esteemed clients. We
                  are incredibly grateful for your unwavering trust and support
                  in transforming our vision into a thriving reality.
                </p>
                <p
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Here's to many more years of pioneering virtual excellence
                  together!
                  <br />
                  <b>Mr. Saurabh Dubey</b> & <b>Mr. Vishal Dubey</b> Founding Pillars,
                  (LiveYards)
                </p>

            
              </div>
              <div
                className="rts-support__wrapper--image sal-animate"
                data-sal="slide-left"
                data-sal-delay="400"
                data-sal-duration="800"
              >
                <img src="assets/images/about/resell/image-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
