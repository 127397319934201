import React from "react";
import "../../node_modules/sal.js/dist/sal.css";

export default function LiveStreamingWebCast() {
  return (
    <>
       <div className="event-managment-section p-0 m-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <img src="assets/images/service/4.jpg" width="100%" alt="img"/>
          </div>
        </div>
      </div>
     </div>

      <div className="rts-about wp__hosting__option position-relative section__padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="rts-about__image">
                <img src="assets/images/about/4500267.webp" alt="" />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Streaming - Live Online
                </h3>
                <p
                  className="description sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                >
                  In today's digital age, live streaming has emerged as a
                  powerful tool for communication. Widely utilised in virtual
                  and hybrid events, this technology offers unmatched reach to a
                  diverse audience, enabling real-time dissemination of messages
                  across various platforms. With just an internet connection,
                  you can connect with audiences worldwide.
                  <br />
                  <br />
                  At BroadcastWorks, we empower you to share your narrative.
                  Leveraging cutting-edge technology and tailored features such
                  as security protocols, interactive chatbots, customised
                  branding, and live polling, we ensure that your voice
                  resonates with a global audience.
                  <br />
                  <br />
                  Our live streaming solutions encompass promotional events,
                  multi-location Continuing Medical Education (CME) sessions,
                  town hall gatherings, and new product launches.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="rts-about__content">
                <h3
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Experience - Social Media Live Streaming
                </h3>
                <p
                  className="description sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                >
                  In today's video-centric world, live streaming through social
                  media platforms has become more accessible than ever. Whether
                  it's YouTube Streaming, designed for brand promotion,
                  educational sharing, or hosting live concerts, these platforms
                  offer an engaging way to connect with your target audience and
                  generate valuable leads.
                  <br />
                  <br />
                  At Digital Streamers, we understand the importance of ensuring
                  your event stands out. We provide the essential elements to
                  take your live streaming experience to the next level. Social
                  media platforms come equipped with built-in live streaming
                  software, complete with captivating animations and visual
                  effects that effectively capture the attention of your desired
                  audience.
                  <br />
                  <br />
                  Our expertise covers popular virtual spaces, including
                  YouTube, Facebook, Instagram, LinkedIn, and Twitter, enabling
                  you to maximise your reach and impact
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="rts-about__image">
                <img
                  src="assets/images/about/live_stream_video_analysis_with_computer_vision.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rts-about-shape"></div>
      </div>

      <div className="rts-data-center no-bg fix section__padding pb-0 pt-0">
        <div className="container">
          <div className="rts-cta-two__wrapper justify-content-center">
            <div className="row">
              <div className="col-12">
                <div className="rts-section text-center mb-0" id='Expertise-events'>
                  <h3
                    className="rts-section__title sal-animate text-white"
                    data-sal="slide-down"
                    data-sal-delay="300"
                    data-sal-duration="800"
                    
                  >
                    Our Expertise Includes 
                  </h3>
                <ul className="expertise-items">
                  <li>Private Urls</li>
                  <li>Analytics</li>
                  <li>Chat Box</li>
                  <li>Registration</li>
                  <li>Feedback</li>
                  <li>Polls</li>
                </ul>
                <ul className="expertise-items">
                  <li>Survey</li>
                  <li>Registration</li>
                  <li>Engagement Activity </li>
                  <li>Reaction</li>
                  <li>OTP Solution</li>
                  <li>Dashboard</li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rts-about-reseller section__padding area-2 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="hosting-info w-550 mt-md-0">
                        <h3 className="hosting-info__title sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">Live Streams Have Diverse Applications!</h3>
                        <p className="description sal-animate" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800">Whether it's internal meetings or trade shows, effective communication is indispensable for the success of any business endeavour. Live streaming offers a dynamic platform, enabling companies to deliver their brand messages, innovations, or business pitches directly to their intended audiences worldwide in real-time.
                        <br/><br/>
                        At LiveYards, our objective is to remove the limitations of physical boundaries and facilitate connections between you and your prospective clients, investors, and global partners through our cutting-edge live streaming solutions. We provide end-to-end support in webcast streaming, encompassing infrastructure, technology, and the integration of the latest tools and methodologies to ensure seamless execution
                        </p>
                       
                    </div>
                </div>
                <div className="col-lg-6 padding-bottom-75">
                    <div className="hosting-about-image-bg"></div>
                    <div className="hosting-about-image">
                        <div className="img-one">
                            <img src="assets/images/about/resell/image-3.png" width="378" height="400" alt="" />
                        </div>
                        <div className="img-two">
                            <img src="assets/images/about/resell/image-4.png" width="247" height="347" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  );
}
