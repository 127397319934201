import React from "react";

export default function Careers() {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530 contact__banner">
                {/* <span className="starting__price"></span> */}
                <h1 className="banner-title">Career</h1>
                <p className="slogan">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                  laudantium debitis eaque harum vel repellat, velit
                  accusantium! Facilis, cumque quo.
                </p>
              </div>
              <div className="rts-hosting-banner__image contact">
                <img
                  src="assets/images/banner/banner__contact__image.svg"
                  alt=""
                />
                <img
                  className="shape one"
                  src="assets/images/banner/banner__contact__image-sm1.svg"
                  alt=""
                />
                <img
                  className="shape two top-bottom2"
                  src="assets/images/banner/banner__contact__image-sm2.svg"
                  alt=""
                />
                <img
                  className="shape three"
                  src="assets/images/banner/banner__contact__image-sm3.svg"
                  alt=""
                />
                <img
                  className="shape four left-right2"
                  src="assets/images/banner/banner__contact__image-sm4.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rts-hosting-feature bg-white section__padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-5">
              <div className="hosting-feature wordpress">
                <h2
                  className="title fw-bold sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  Join Our Epic Team At Live Yards.
                </h2>
                <p
                  className="hosting-feature__desc sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                >
                  'Lorem ipsum dolor sit amet consectetur, adipisicing elit. In,
                  nobis nostrum eum, iure molestiae fugit at facilis libero
                  assumenda ipsam id omnis qui. Vitae laborum blanditiis error
                  magnam praesentium illum ea excepturi? Dolorem nam velit fuga
                  expedita, iure vero doloribus atque sunt adipisci animi. Nulla
                  facilis modi nihil aperiam nisi.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 offset-xl-1 col-xl-6 sal-animate"
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="800"
            >
              <div className="hosting-feature-image">
                <div className="hosting-feature-image__image2 wordpress">
                  <img
                    src="assets/images/banner/banner__vps__image.svg"
                    width="647"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="rts-faq section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="rts-faq__first">
                <h3
                  className="title sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                >
                  CURRENT OPENINGS
                </h3>
                <p
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  When you find job openings, make sure to tailor your
                  application materials (resume, cover letter) to match the job
                  requirements and company culture
                </p>
                <img
                  data-sal="slide-down"
                  data-sal-delay="500"
                  data-sal-duration="800"
                  src="assets/images/banner/banner__business__mail__image.svg"
                  alt="faq"
                  className="sal-animate"
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="rts-faq__accordion">
                <div className="accordion accordion-flush" id="rts-accordion">
                  <div
                    className="accordion-item active sal-animate"
                    data-sal="slide-left"
                    data-sal-delay="300"
                    data-sal-duration="800"
                  >
                    <div className="accordion-header" id="first">
                      <h4
                        className="accordion-button collapse show"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__one"
                        aria-expanded="false"
                        aria-controls="item__one"
                      >
                        Trainee Streaming Engineer - Live Events
                      </h4>
                    </div>
                    <div
                      id="item__one"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="first"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      <b>Job Summary:</b> <br/>  As a Streaming Engineer at 24 Frames
                        Digital, you will play a crucial role in ensuring the
                        seamless execution of live streaming for our clients'
                        events. You will be responsible for setting up,
                        monitoring, and troubleshooting live streaming equipment
                        and software to deliver high-quality live broadcasts.
                        <br />
                        <br />
                        <b>Key Responsibilities:</b>
                        <br />
                        1. Live Stream Setup: Configure and set up live
                        streaming equipment, cameras, microphones, and encoding
                        software for events. Ensure proper connectivity,
                        network, and hardware configurations for uninterrupted
                        live streams.
                        <br />
                        2. Quality Control: Monitor and maintain the quality of
                        live video and audio streams during events. Troubleshoot
                        and resolve technical issues in real-time to minimize
                        disruptions.
                        <br />
                        3. Technical Expertise: Stay updated with the latest
                        streaming technologies and software. Implement new
                        streaming solutions and technologies to enhance the live
                        streaming experience.
                        <br />
                        4. Implement new streaming solutions and technologies to
                        enhance the live streaming experience.
                        <br />
                        Work closely with the event management team to
                        understand event requirements and technical
                        specifications. Coordinate with the Digital Marketing
                        team to ensure seamless integration with online
                        platforms.
                        <br />
                        5. Testing and Optimization: Conduct pre-event testing
                        to verify stream quality and reliability. Optimize
                        streaming parameters for different types of events,
                        ensuring the best viewer experience.
                        <br />
                        6. Documentation: Maintain detailed documentation of
                        equipment setup and streaming configurations. Create
                        reports on streaming performance and suggest
                        improvements.
                        <br />
                        <br />
                        <b>Qualifications:</b>
                        <br />
                        Bachelor's degree with specialization in IT related
                        field with any Hardware/Network related course Good
                        knowledge of IT (Hardware and Network) Understanding of
                        Live Streaming will be added advantage Strong
                        problem-solving skills and the ability to troubleshoot
                        technical issues in real-time. Familiarity with video
                        and audio equipment and their setup. Excellent
                        communication and teamwork skills. A passion for staying
                        updated with the latest streaming technologies and
                        trends.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item sal-animate"
                    data-sal="slide-left"
                    data-sal-delay="400"
                    data-sal-duration="800"
                  >
                    <div className="accordion-header" id="two">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__two"
                        aria-expanded="false"
                        aria-controls="item__two"
                      >
                        Business Development Executive
                      </h4>
                    </div>
                    <div
                      id="item__two"
                      className="accordion-collapse collapse"
                      aria-labelledby="two"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      <b>Job Summary:</b> <br/>  As a Streaming Engineer at 24 Frames
                        Digital, you will play a crucial role in ensuring the
                        seamless execution of live streaming for our clients'
                        events. You will be responsible for setting up,
                        monitoring, and troubleshooting live streaming equipment
                        and software to deliver high-quality live broadcasts.
                        <br />
                        <br />
                        <b>Key Responsibilities:</b>
                        <br />
                        1. Live Stream Setup: Configure and set up live
                        streaming equipment, cameras, microphones, and encoding
                        software for events. Ensure proper connectivity,
                        network, and hardware configurations for uninterrupted
                        live streams.
                        <br />
                        2. Quality Control: Monitor and maintain the quality of
                        live video and audio streams during events. Troubleshoot
                        and resolve technical issues in real-time to minimize
                        disruptions.
                        <br />
                        3. Technical Expertise: Stay updated with the latest
                        streaming technologies and software. Implement new
                        streaming solutions and technologies to enhance the live
                        streaming experience.
                        <br />
                        4. Implement new streaming solutions and technologies to
                        enhance the live streaming experience.
                        <br />
                        Work closely with the event management team to
                        understand event requirements and technical
                        specifications. Coordinate with the Digital Marketing
                        team to ensure seamless integration with online
                        platforms.
                        <br />
                        5. Testing and Optimization: Conduct pre-event testing
                        to verify stream quality and reliability. Optimize
                        streaming parameters for different types of events,
                        ensuring the best viewer experience.
                        <br />
                        6. Documentation: Maintain detailed documentation of
                        equipment setup and streaming configurations. Create
                        reports on streaming performance and suggest
                        improvements.
                        <br />
                        <br />
                        <b>Qualifications:</b>
                        <br />
                        Bachelor's degree with specialization in IT related
                        field with any Hardware/Network related course Good
                        knowledge of IT (Hardware and Network) Understanding of
                        Live Streaming will be added advantage Strong
                        problem-solving skills and the ability to troubleshoot
                        technical issues in real-time. Familiarity with video
                        and audio equipment and their setup. Excellent
                        communication and teamwork skills. A passion for staying
                        updated with the latest streaming technologies and
                        trends.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item sal-animate"
                    data-sal="slide-left"
                    data-sal-delay="500"
                    data-sal-duration="800"
                  >
                    <div className="accordion-header" id="three">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__three"
                        aria-expanded="false"
                        aria-controls="item__three"
                      >
                        Business Development Manager
                      </h4>
                    </div>
                    <div
                      id="item__three"
                      className="accordion-collapse collapse"
                      aria-labelledby="three"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                       <b>Job Summary:</b> <br/> As a Streaming Engineer at 24 Frames
                        Digital, you will play a crucial role in ensuring the
                        seamless execution of live streaming for our clients'
                        events. You will be responsible for setting up,
                        monitoring, and troubleshooting live streaming equipment
                        and software to deliver high-quality live broadcasts.
                        <br />
                        <br />
                        <b>Key Responsibilities:</b>
                        <br />
                        1. Live Stream Setup: Configure and set up live
                        streaming equipment, cameras, microphones, and encoding
                        software for events. Ensure proper connectivity,
                        network, and hardware configurations for uninterrupted
                        live streams.
                        <br />
                        2. Quality Control: Monitor and maintain the quality of
                        live video and audio streams during events. Troubleshoot
                        and resolve technical issues in real-time to minimize
                        disruptions.
                        <br />
                        3. Technical Expertise: Stay updated with the latest
                        streaming technologies and software. Implement new
                        streaming solutions and technologies to enhance the live
                        streaming experience.
                        <br />
                        4. Implement new streaming solutions and technologies to
                        enhance the live streaming experience.
                        <br />
                        Work closely with the event management team to
                        understand event requirements and technical
                        specifications. Coordinate with the Digital Marketing
                        team to ensure seamless integration with online
                        platforms.
                        <br />
                        5. Testing and Optimization: Conduct pre-event testing
                        to verify stream quality and reliability. Optimize
                        streaming parameters for different types of events,
                        ensuring the best viewer experience.
                        <br />
                        6. Documentation: Maintain detailed documentation of
                        equipment setup and streaming configurations. Create
                        reports on streaming performance and suggest
                        improvements.
                        <br />
                        <br />
                        <b>Qualifications:</b>
                        <br />
                        Bachelor's degree with specialization in IT related
                        field with any Hardware/Network related course Good
                        knowledge of IT (Hardware and Network) Understanding of
                        Live Streaming will be added advantage Strong
                        problem-solving skills and the ability to troubleshoot
                        technical issues in real-time. Familiarity with video
                        and audio equipment and their setup. Excellent
                        communication and teamwork skills. A passion for staying
                        updated with the latest streaming technologies and
                        trends.
                      </div>
                    </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rts-contact-form no-bg pt--30 pb--30">
        <div className="container">
        
          <div className="row gy-30 justify-content-center">
            
            <div className="col-xl-8 col-lg-6 offset-xl-1 col-md-10">
            <h3
                  className="title sal-animate text-center fw-bold"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                >
                  Upload Resume
                </h3>
              <div className="form">
                <form className="form__content" method="post" action="">
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="name"
                      id="name"
                      placeholder="what is your name?"
                      required=""
                    />
                    <input
                      type="email"
                      className="input-form"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required=""
                    />
                  </div>
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      required=""
                    />
                    <select
                      name="select"
                      id="select"
                      className="input-form"
                      style={{ display: "none" }}
                    >
                      <option value="1">Select a state</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="India">India</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Maldives">Maldives</option>
                    </select>
                  
                    <div className="nice-select input-form" tabindex="0">
                      <span className="current">Select a state</span>
                      <ul className="list">
                        <li data-value="1" className="option selected">
                          Select a state
                        </li>
                        <li data-value="Bangladesh" className="option">
                          Bangladesh
                        </li>
                        <li data-value="India" className="option">
                          India
                        </li>
                        <li data-value="Pakistan" className="option">
                          Pakistan
                        </li>
                        <li data-value="Nepal" className="option">
                          Nepal
                        </li>
                        <li data-value="Maldives" className="option">
                          Maldives
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="form__control">
                    <input type="file" className="input-form p-0" name="name" id="file" placeholder="what is your name?" />
                  </div>

                  <textarea
                  className="p-0"
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="About your Self.."
                    required=""
                  ></textarea>
                  <input type="checkbox" name="checkbox" id="checkbox" />
                  <label for="checkbox">
                    By submitting your information you provide written consent
                    to Live Yards and its family of brands contacting you.
                  </label>
                  <button type="submit" className="submit__btn">
                    Submit Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
